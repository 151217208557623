import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
//const TWEEN = require('@tweenjs/tween.js')
import * as TWEEN from '@tweenjs/tween.js'
import { Vector2 } from 'three'

import { request } from 'graphql-request';

let MOVEWITHMOUSE = true

let projectArr = []
request(
    'https://api-eu-central-1.graphcms.com/v2/cl1fd6cuz3a1b01z775hh5wbu/master',
    `
        {
            axelmuschProjects {
             projectId
                projectTitle
              projectText
              projectUrl
              projectImage {
                id
                url
              }
              
            }
          }
        `
).then(data => {

    projectArr = data.axelmuschProjects

    projectArr.forEach((val, idx) => {
        let html = ""

        html =
            '<img class="projectImg" src="' + val.projectImage.url + '" />' +
            '<h2>' + val.projectTitle + '</h2>'

        const tempWrapper = document.createElement('div');
        tempWrapper.innerHTML = html;
        tempWrapper.classList.add('projectTile');
        tempWrapper.id = "project_" + val.projectId
        document.querySelector(".projectsContainer").appendChild(tempWrapper)


    })

    let elements = document.getElementsByClassName("projectTile");
    for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', handleProjectClick, false);
    }

    document.querySelector(".projects--detail--title").innerHTML = projectArr[0].projectTitle
    document.querySelector(".projects--detail--text").innerHTML = projectArr[0].projectText
    document.querySelector(".projects--detail--link").href = projectArr[0].projectUrl
    document.querySelector(".projects--detail--img").src = projectArr[0].projectImage.url
});








let camera
const textureLoader = new THREE.TextureLoader()
//const normalTexture = textureLoader.load("./textures/14582-normal.jpg")
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')
//Music by ZakharValaha from Pixabay
var audioMove = new Audio('./music/soundMove.mp3');
audioMove.volume = 0.03


var audio = new Audio('./music/ambiant-cinematic-drone-main-10526.mp3');
audio.volume = 0.03
audio.loop = true
audio.play();

// Scene
const scene = new THREE.Scene()
let ALLOW_NEXT = true
let currentCamPos = 0
const camPositions = [
    { x: 0, y: 0, z: 4 },
    { x: 18, y: -4, z: -16 },
    { x: 0, y: 0, z: -36 },
    { x: -18, y: 4, z: -56 }
]

let mouse = new THREE.Vector2(0, 0)

// Objects
const geo_torus = new THREE.TorusBufferGeometry(1.4, .6, 100, 100);
//const geo_planet = new THREE.CylinderGeometry(2, 2, 0.1, 32);
const geo_planet = new THREE.SphereBufferGeometry(1.5, 64, 64);
const geo_planet1 = new THREE.TorusBufferGeometry(2, 0.2, 30, 50);
const geo_dodecahedron = new THREE.DodecahedronBufferGeometry(1, 0);

const particlesGeometry = new THREE.BufferGeometry();
const particlesCount = 5000;
const posArray = new Float32Array(particlesCount * 3)



for (let i = 0; i < particlesCount * 3; i++) {
    posArray[i] = (Math.random() - 0.5) * 150
}
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3))

const pointsMaterial = new THREE.PointsMaterial({
    size: .1,
    //alphaMap: new THREE.TextureLoader().load('textures/star.png'),
    map: new THREE.TextureLoader().load('./textures/star.png'),
    transparent: true
})
const particlesMesh = new THREE.Points(particlesGeometry, pointsMaterial)
scene.add(particlesMesh)


// Materials
//const material = new THREE.MeshStandardMaterial();
const material = new THREE.MeshStandardMaterial();
material.roughness = 0.8
material.metalness = 0

//material.normalMap = normalTexture
//material.normalMap.center = new Vector2(0.5, 0.5)
//material.normalScale = new THREE.Vector2(0.6, 0.6)
material.flatShading = true
material.color = new THREE.Color(0x725e74)

// Mesh
const schijf = new THREE.Mesh(geo_planet, material)
schijf.rotation.z = -0.3
schijf.rotation.x = 0.6
schijf.position.x = -1
scene.add(schijf)

const count = geo_planet.attributes.position.count
const position_clone_planet = JSON.parse(
    JSON.stringify(geo_planet.attributes.position.array));
const normals_clone_planet = JSON.parse(
    JSON.stringify(geo_planet.attributes.normal.array));

const ringMaterial = new THREE.MeshStandardMaterial();
ringMaterial.roughness = 0.8
ringMaterial.metalness = 0

let ringtexture = new THREE.TextureLoader().load('./textures/14582-normal.jpg')

ringtexture.wrapS = THREE.RepeatWrapping;
ringtexture.wrapT = THREE.RepeatWrapping;
ringtexture.repeat.set(8, 1);

//ringMaterial.normalMap = ringtexture
ringMaterial.transparent = true
ringMaterial.opacity = 0.7
//ringMaterial.normalScale = new THREE.Vector2(0.9, 0.9)
ringMaterial.flatShading = true
ringMaterial.color = new THREE.Color(0x725e74)

const count_ring = geo_planet1.attributes.position.count
const position_clone_planet1 = JSON.parse(
    JSON.stringify(geo_planet1.attributes.position.array));
const normals_clone_planet1 = JSON.parse(
    JSON.stringify(geo_planet1.attributes.normal.array));

const ring = new THREE.Mesh(geo_planet1, ringMaterial)
ring.rotation.x = -1.1
ring.rotation.y = 0.2
ring.position.x = -1
ring.position.y = 0.3
ring.scale.set(1, 1, 0.5)
scene.add(ring)

const torusMaterial = new THREE.MeshStandardMaterial();
torusMaterial.roughness = 0.8
//torusMaterial.metalness = 0.7
//torusMaterial.map = new THREE.TextureLoader().load('textures/sand.jpg')
//torusMaterial.normalMap = new THREE.TextureLoader().load('textures/normal-pattern.jpeg')
//torusMaterial.normalMap.center = new Vector2(0.5, 0.5)
//torusMaterial.normalScale = new THREE.Vector2(1, 1)
torusMaterial.flatShading = true
torusMaterial.color = new THREE.Color(0xFF8C00)


const torus = new THREE.Mesh(geo_torus, torusMaterial)
torus.position.x = 18
torus.position.y = -4
torus.position.z = -20
torus.castShadow = true
scene.add(torus)

const count_torus = geo_torus.attributes.position.count
const position_clone_torus = JSON.parse(
    JSON.stringify(geo_torus.attributes.position.array));
const normals_clone_torus = JSON.parse(
    JSON.stringify(geo_torus.attributes.normal.array));

const dode_material = new THREE.MeshStandardMaterial();
/* dode_material.roughness = 0.2
dode_material.metalness = 0.7 */

dode_material.flatShading = true
dode_material.color = new THREE.Color(0xFDDA0D)


const dode = new THREE.Mesh(geo_dodecahedron, dode_material)
dode.position.x = -2
dode.position.y = 0
dode.position.z = -40
scene.add(dode)

const dode2 = new THREE.Mesh(geo_dodecahedron, dode_material)
dode2.position.x = 2
dode2.position.y = 0
dode2.position.z = -40
scene.add(dode2)

const box_material = new THREE.MeshStandardMaterial()
box_material.flatShading = true
box_material.color = new THREE.Color(0x1212ff)

const box_geo = new THREE.BoxBufferGeometry(1, 1, 1, 64, 64)
const box = new THREE.Mesh(box_geo, box_material)
box.position.x = -18
box.position.y = 4
box.position.z = -58
scene.add(box)


// Lights

const pointLight = new THREE.PointLight(0xffffff, 1, 5)
pointLight.position.x = -2
pointLight.position.y = 0.9
pointLight.position.z = 1.8
const pointLightHelper = new THREE.PointLightHelper(pointLight, 1);
//scene.add(pointLightHelper);
scene.add(pointLight)

const pointLightB = new THREE.PointLight(0xffffff, 1, 5)
pointLightB.position.x = 1
pointLightB.position.y = 0.5
pointLightB.position.z = 2
const pointLightHelperB = new THREE.PointLightHelper(pointLightB, 1);
//scene.add(pointLightHelperB);
scene.add(pointLightB)

const pointLightC = new THREE.PointLight(0xffffff, 1, 5)
pointLightC.position.x = -1
pointLightC.position.y = -1
pointLightC.position.z = 2
const pointLightHelperC = new THREE.PointLightHelper(pointLightC, 1);
//scene.add(pointLightHelperC);
scene.add(pointLightC)


const torus_light1 = new THREE.PointLight(0xffffff, 1, 5)
torus_light1.position.x = 18
torus_light1.position.y = -4
torus_light1.position.z = -20
const pointLightHelper2 = new THREE.PointLightHelper(torus_light1, 1);
//scene.add(pointLightHelper2);
scene.add(torus_light1)

const torus_light2 = new THREE.PointLight(0xffffff, 2, 5)
torus_light2.position.x = 19
torus_light2.position.y = -4
torus_light2.position.z = -17
const pointLightHelper3 = new THREE.PointLightHelper(torus_light2, 1);
//scene.add(pointLightHelper3);
scene.add(torus_light2)

const dode_light1 = new THREE.PointLight(0xffffff, 2, 5)
dode_light1.position.x = -2
dode_light1.position.y = -2
dode_light1.position.z = -39
const dode_light1_helper = new THREE.PointLightHelper(dode_light1, 1);
//scene.add(dode_light1_helper);
scene.add(dode_light1)

const dode_light2 = new THREE.PointLight(0xffffff, 2, 5)
dode_light2.position.x = 2
dode_light2.position.y = 2
dode_light2.position.z = -39
const dode_light2_helper = new THREE.PointLightHelper(dode_light2, 1);
//scene.add(dode_light2_helper);
scene.add(dode_light2)

const box_light_1 = new THREE.PointLight(0xffffff, 2, 5)
box_light_1.position.x = -20
box_light_1.position.y = 4
box_light_1.position.z = -57
const box_light_1_helper = new THREE.PointLightHelper(box_light_1, 1);
//scene.add(box_light_1_helper);
scene.add(box_light_1)

const box_light_2 = new THREE.PointLight(0xffffff, 2, 5)
box_light_2.position.x = -16
box_light_2.position.y = 4
box_light_2.position.z = -57
const box_light_2_helper = new THREE.PointLightHelper(box_light_2, 1);
//scene.add(box_light_2_helper);
scene.add(box_light_2)

let smokeSize = 1

var manager = new THREE.LoadingManager();
manager.onProgress = function (item, loaded, total) {
    //console.log((loaded / total * 100) + '%');
    //$(".percentLoaded").text((loaded / total * 100) + '%')
    //$("#loadprogress").css("width", (loaded / total * 100) + '%')
};

manager.onLoad = function () {

    console.log('Loading complete!');
    document.querySelector("#loadprogress").style.width = "100%"
    setTimeout(() => {
        document.querySelector(".loadscreen").style.marginTop = "-100vh"
    }, 2000);

};
let smokeTexture = new THREE.TextureLoader(manager).load('./textures/smoke.png');
let smokeMaterial = new THREE.MeshBasicMaterial({ color: 0x725e74 /*0x725e74*/, map: smokeTexture, transparent: true, opacity: 1 });
let smokeGeo = new THREE.PlaneGeometry(1000, 1000);
let smokeParticles = [];


for (let p = 0; p < 250; p++) {
    var particle = new THREE.Mesh(smokeGeo, smokeMaterial);
    //particle.position.set(Math.random() * 50 - 25, Math.random() * 500 - 25, Math.random() * 100 - 10);
    let r = (Math.random() * 3000 - 2000)
    //particle.position.set(Math.random() * 200 - 100, Math.random() * 50 - 25, -50/* Math.random() * 20 - 2 */);
    particle.position.set(r, r + Math.random() * 3000 - 2000, -500/* Math.random() * 20 - 2 */);

    particle.rotation.z = Math.random() * 36;
    particle.renderOrder = -1
    scene.add(particle);
    smokeParticles.push(particle);
}

/* const light = new THREE.AmbientLight(0x404040, 3); // soft white light
scene.add(light); */

const lightFolder = gui.addFolder('Lights')
const planet_light_folder = lightFolder.addFolder("planet_light")
const torus_light1_folder = lightFolder.addFolder("torus_licht1")
const torus_light2_folder = lightFolder.addFolder("torus_licht2")
const dode_light1_folder = lightFolder.addFolder("dode_light1")

planet_light_folder.add(pointLight.position, "x").min(-3).max(3).step(0.1)
planet_light_folder.add(pointLight.position, "y").min(-3).max(3).step(0.1)
planet_light_folder.add(pointLight.position, "z").min(-3).max(3).step(0.1)

let temp_tl1 = torus_light1.position
torus_light1_folder.add(torus_light1.position, "x").min(temp_tl1.x - 5).max(temp_tl1.x + 5).step(0.1)
torus_light1_folder.add(torus_light1.position, "y").min(temp_tl1.y - 5).max(temp_tl1.y + 5).step(0.1)
torus_light1_folder.add(torus_light1.position, "z").min(temp_tl1.z - 5).max(temp_tl1.z + 5).step(0.1)

let temp_tl2 = torus_light2.position
torus_light2_folder.add(torus_light2.position, "x").min(temp_tl2.x - 5).max(temp_tl2.x + 5).step(0.1)
torus_light2_folder.add(torus_light2.position, "y").min(temp_tl2.y - 5).max(temp_tl2.y + 5).step(0.1)
torus_light2_folder.add(torus_light2.position, "z").min(temp_tl2.z - 5).max(temp_tl2.z + 5).step(0.1)

let temp_tl3 = dode_light1.position
dode_light1_folder.add(dode_light1.position, "x").min(temp_tl3.x - 5).max(temp_tl3.x + 5).step(0.1)
dode_light1_folder.add(dode_light1.position, "y").min(temp_tl3.y - 5).max(temp_tl3.y + 5).step(0.1)
dode_light1_folder.add(dode_light1.position, "z").min(temp_tl3.z - 5).max(temp_tl3.z + 5).step(0.1)


const schijfFolder = gui.addFolder('planet')
schijfFolder.add(schijf.position, "x").min(-3).max(3).step(0.1)
schijfFolder.add(schijf.position, "y").min(-3).max(3).step(0.1)
schijfFolder.add(schijf.position, "z").min(-3).max(3).step(0.1)

const ringFolder = gui.addFolder('Ring')
ringFolder.add(ring.rotation, "x").min(-3).max(3).step(0.1)
ringFolder.add(ring.rotation, "y").min(-3).max(3).step(0.1)
ringFolder.add(ring.rotation, "z").min(-3).max(3).step(0.1)

const torusFolder = gui.addFolder('torus')
torusFolder.add(torus.rotation, "x").min(-3).max(3).step(0.1)
torusFolder.add(torus.rotation, "y").min(-3).max(3).step(0.1)
torusFolder.add(torus.rotation, "z").min(-3).max(3).step(0.1)

const dodeFolder = gui.addFolder('dode')
dodeFolder.add(dode.rotation, "x").min(-3).max(3).step(0.1)
dodeFolder.add(dode.rotation, "y").min(-3).max(3).step(0.1)
dodeFolder.add(dode.rotation, "z").min(-3).max(3).step(0.1)

const torusColor = { color: 0xcdc02b }
torusFolder.addColor(torusColor, "color")
    .onChange(() => {
        torus.material.color.set(torusColor.color)
    })



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener("wheel", (event) => {

    if (event.target.classList.contains("projectsContainer") || event.target.classList.contains("projectTile")) {

    } else {
        if (ALLOW_NEXT) {
            if (event.deltaY > 0 && currentCamPos != camPositions.length - 1) {
                // downscroll code
                changeView('next')

            } else if (event.deltaY < 0 && currentCamPos != 0) {
                // upscroll code
                changeView('prev')

            }
        }
    }


}, false)

window.addEventListener('mousemove', (e) => {
    let prevMouseX = mouse.x
    let prevMouseY = mouse.y

    mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;

    let deltaMouseX = mouse.x - prevMouseX
    let deltaMouseY = mouse.y - prevMouseY
    if (camera && MOVEWITHMOUSE) {
        camera.translateX(deltaMouseX / 4)
        camera.translateY(deltaMouseY / 4)
    }


    document.getElementById("tekst_0_1").s

    if (MOVEWITHMOUSE) {
        dampings.torus.x = mouse.x / 10
        dampings.torus.y = mouse.y / 10
    }

    //document.getElementById("tekst_0").style.transform = "translate(-" + mouse.x * 10 + "px,-" + mouse.y * 10 + "px)"
})


var touchPos;

document.body.ontouchstart = function (e) {
    MOVEWITHMOUSE = false
    touchPos = e.changedTouches[0].clientY;
}

document.body.ontouchmove = function (e) {

    if (e.target.classList.contains("projectsContainer") || e.target.classList.contains("projectTile")) {

    } else {

        if (ALLOW_NEXT) {
            let newTouchPos = e.changedTouches[0].clientY;

            if ((newTouchPos > touchPos) && currentCamPos != 0) {
                changeView('prev')
            }
            if ((newTouchPos < touchPos) && currentCamPos != camPositions.length - 1) {
                changeView('next')

            }
        }
    }
}


window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

document.getElementById("menuHome").onclick = function () {
    if (currentCamPos != 0 && ALLOW_NEXT) changeView(0)
};

document.getElementById("menuAbout").onclick = function () {
    if (currentCamPos != 1 && ALLOW_NEXT) changeView(1)
};

document.getElementById("menuWork").onclick = function () {
    if (currentCamPos != 2 && ALLOW_NEXT) changeView(2)
};

document.getElementById("menuContact").onclick = function () {
    if (currentCamPos != 3 && ALLOW_NEXT) changeView(3)
};

document.getElementById("toggleMute").onclick = function () {
    // Mute a singular HTML5 element
    /* mutePage()

    function muteMe(elem) {
        elem.muted = true;
        elem.pause();
    }

    // Try to mute all video and audio elements on the page
    function mutePage() {
        document.querySelectorAll("video, audio").forEach(elem => muteMe(elem));
    } */

    audioMove.muted = !audioMove.muted
    audio.muted = !audio.muted
    document.getElementById("toggleMute").classList.toggle("iconSound");
    document.getElementById("toggleMute").classList.toggle("iconMuted");

};

document.getElementById('scrollLeft').onclick = function () {
    document.querySelector('.projectsContainer').scroll({
        left: document.querySelector('.projectsContainer').scrollLeft - 100,
        behavior: 'smooth'
    })
}

document.getElementById('scrollRight').onclick = function () {
    document.querySelector('.projectsContainer').scroll({
        left: document.querySelector('.projectsContainer').scrollLeft + 100,
        behavior: 'smooth'
    })
}





var handleProjectClick = function () {
    var attribute = this.getAttribute("id");

    projectArr.forEach((val, idx) => {
        if (val.projectId == attribute.split("_")[1]) {
            document.querySelector(".projects--detail--title").innerHTML = val.projectTitle
            document.querySelector(".projects--detail--text").innerHTML = val.projectText
            document.querySelector(".projects--detail--link").href = val.projectUrl
            document.querySelector(".projects--detail--img").src = val.projectImage.url

        }
    })
};









/**
 * Camera
 */
// Base camera
camera = new THREE.PerspectiveCamera(90, sizes.width / sizes.height, 0.1, 1000)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 4
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))




const first = document.querySelector("#tekst_0")
gsap.to(first, { duration: 1, ease: "circ.out", clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" })



function changeView(upOrDown) {

    const n = document.querySelector("#tekst_" + currentCamPos)

    gsap.to(n, {
        duration: 1, clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)", onComplete: () => {
            gsap.to(n, { duration: 0, display: "none" })
            gsap.to(n, { duration: 0, clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)" })
        }
    })

    ALLOW_NEXT = false
    audioMove.play()
    audio.play();

    setTimeout(() => {

        if (isNaN(upOrDown)) {
            if (upOrDown == "next") {
                currentCamPos++
            } else if (upOrDown == "prev") {
                currentCamPos--
            }
        } else {
            currentCamPos = upOrDown
        }



        let toX = camPositions[currentCamPos].x
        let toY = camPositions[currentCamPos].y
        let toZ = camPositions[currentCamPos].z

        let tweencam = new TWEEN.Tween(camera.position)
            .to({ x: toX, y: toY, z: toZ }, 2000)
            .easing(TWEEN.Easing.Cubic.InOut)
            .onUpdate(() => { })
            .onComplete(() => {

                //audioMove.pause()


                let n = "tekst_" + currentCamPos

                let element = document.querySelector("#" + n)

                gsap.to(element, {
                    duration: 0, display: "flex", onComplete: () => {
                        gsap.to(element, {
                            duration: 1, clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)", onComplete: () => {

                                ALLOW_NEXT = true

                            }
                        })

                    }
                })
            })
            .start()
    }, 250);
}

const clock = new THREE.Clock()
const damping_planet = 0.05;
const damping_ring = 0.01;
const damping_torus = 0.02;
const dampings = { planet: 0.02, torus: { x: 0.02, y: 0.02 } }



const tick = () => {

    const elapsedTime = clock.getElapsedTime()
    const delta = clock.getDelta()
    const now = Date.now() / 300

    for (let i = 0; i < count; i++) {
        const uX = geo_planet.attributes.uv.getX(i) * Math.PI * 16
        const uY = geo_planet.attributes.uv.getY(i) * Math.PI * 16

        const xangle = (uX + now)
        const xsin = Math.sin(xangle) * damping_planet
        const yangle = (uY + now)
        const ycos = Math.sin(yangle) * damping_planet

        const ix = i * 3
        const iy = i * 3 + 1
        const iz = i * 3 + 2

        geo_planet.attributes.position.setX(i, position_clone_planet[ix] + normals_clone_planet[ix] * (xsin + ycos))
        //geo_planet.attributes.position.setY(i, position_clone_planet[iy] + normals_clone_planet[iy] * (xsin + ycos))
        geo_planet.attributes.position.setZ(i, position_clone_planet[iz] + normals_clone_planet[iz] * (xsin + ycos))

    }
    geo_planet.computeVertexNormals();
    geo_planet.attributes.position.needsUpdate = true

    for (let i = 0; i < count_ring; i++) {
        const uX = geo_planet1.attributes.uv.getX(i) * Math.PI * 16
        const uY = geo_planet1.attributes.uv.getY(i) * Math.PI * 16

        const xangle = (uX + now)
        const xsin = (Math.sin(xangle) * damping_ring)
        const yangle = (uY + now)
        const ycos = (Math.sin(yangle) * damping_ring)

        const ix = i * 3
        const iy = i * 3 + 1
        const iz = i * 3 + 2

        geo_planet1.attributes.position.setX(i, position_clone_planet1[ix] + normals_clone_planet1[ix] * (xsin + ycos))
        //geo_planet1.attributes.position.setY(i, position_clone_planet1[iy] + normals_clone_planet1[iy] * (xsin + ycos))
        geo_planet1.attributes.position.setZ(i, position_clone_planet1[iz] + normals_clone_planet1[iz] * (xsin + ycos))

    }
    geo_planet1.computeVertexNormals();
    geo_planet1.attributes.position.needsUpdate = true




    for (let i = 0; i < count_torus; i++) {
        const uX = geo_torus.attributes.uv.getX(i) * Math.PI * 16
        const uY = geo_torus.attributes.uv.getY(i) * Math.PI * 16

        const xangle = (uX + now)
        const xsin = Math.sin(xangle) * dampings.torus.x
        const yangle = (uY + now)
        const ycos = Math.sin(yangle) * dampings.torus.y

        const ix = i * 3
        const iy = i * 3 + 1
        const iz = i * 3 + 2

        geo_torus.attributes.position.setX(i, position_clone_torus[ix] + normals_clone_torus[ix] * (xsin + ycos))
        geo_torus.attributes.position.setY(i, position_clone_torus[iy] + normals_clone_torus[iy] * (xsin + ycos))
        geo_torus.attributes.position.setZ(i, position_clone_torus[iz] + normals_clone_torus[iz] * (xsin + ycos))

    }
    geo_torus.computeVertexNormals();
    geo_torus.attributes.position.needsUpdate = true

    // Update objects
    schijf.rotation.y = 0.2 * elapsedTime
    ring.rotation.z = -0.05 * elapsedTime
    ring.rotation.y = 0.01 * Math.sin(elapsedTime)

    particlesMesh.rotation.y = 0.02 * elapsedTime
    particlesMesh.rotation.z = 0.02 * elapsedTime

    torus.rotation.y = .4 * elapsedTime
    torus.rotation.z = .4 * elapsedTime

    //gsap.to(torus.material.color, { duration: 1000, ease: "circ.out", r: (Math.cos(elapsedTime) + 1) / 2 })
    //gsap.to(torus.material.color, { duration: 1000, ease: "circ.out", g: (Math.sin(elapsedTime) + 1) / 2 })
    //gsap.to(torus.material.color, { duration: 1000, ease: "circ.out", b: (Math.cos(elapsedTime) + 1) / 2 })

    dode.rotation.y = .4 * elapsedTime
    dode.rotation.z = .4 * elapsedTime

    dode2.rotation.y = .4 * elapsedTime
    dode2.rotation.z = .4 * elapsedTime

    box.rotation.y = .1 * elapsedTime
    box.rotation.z = .4 * elapsedTime

    dode.position.y = 1 * Math.sin(elapsedTime)
    dode2.position.y = 1 * Math.cos(elapsedTime)




    //schijf.rotation.z = .2 * elapsedTime

    // Update Orbital Controls
    // controls.update()

    smokeParticles.forEach((val, idx) => {
        val.rotation.z += 0.001
    })

    // Render
    TWEEN.update()

    renderer.render(scene, camera)
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


function hasTouch() {
    return 'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) { // remove all the :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText) continue;

                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) { }
}


document.onkeydown = checkKey;

function checkKey(e) {
    const scrollContainer = document.querySelector('.projectsContainer')

    e = e || window.event;

    if (e.keyCode == '38') {
        // up arrow
    }
    else if (e.keyCode == '40') {
        // down arrow
    }
    else if (e.keyCode == '37') {
        // left arrow
        scrollContainer.scroll({
            left: scrollContainer.scrollLeft - 100,
            behavior: 'smooth'
        })
    }
    else if (e.keyCode == '39') {
        scrollContainer.scroll({
            left: scrollContainer.scrollLeft + 100,
            behavior: 'smooth'
        })
    }

}

